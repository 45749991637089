import { Headers, Http, Response, RequestOptions } from '@angular/http';

export class Constants{

    public userCreateSuccess = 'Información guardada con éxito.';
    public userUpdateSuccess = 'Información actualizada.';
    public userDeleteSuccess = 'Información eliminada.';

    server = 'https://mantenme.lat/';
    server2 = 'https://mantenme.lat:8082/api/v1/';
    api = 'api/ws-v2/ws/';
    PATH_API = this.server + this.api;
    PATH_API2 = this.server2;


    getHeaders(){
        let headers = new Headers();
        let opts = new RequestOptions();
        //headers.append('Authorization', 'Basic dXNlckFkbWluOkBwcFVzM3Ih');
        headers.append('Content-Type', 'application/json');
        opts.headers = headers;
        return opts;
    }

    getHeaders2(){
        let headers = new Headers();
        let opts = new RequestOptions();
        headers.append('Authorization', 'Basic TUFOVEVOTUVERVZFTE9QTUVOVDIwMjJARkFCI0FQMjI6TUFOVEVOTUVERVZFTE9QTUVOVDIwMjJARkFCI0FQMjJMODJTQUwzWFM=');
        headers.append('Content-Type', 'application/json');
        opts.headers = headers;
        return opts;
    }

}