import { Injectable } from '@angular/core';
import { Headers, Http, Response, RequestOptions } from '@angular/http';
import { Constants } from '../../../constants';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { LocalStoreService } from '../local-store.service';
import { Body } from '@angular/http/src/body';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs/Rx';


@Injectable()
export class MantenmeServices {

  public subject = new Subject<string>();

  public car: any;

  constructor(private http: Http,
    private constants: Constants,
    private ls: LocalStoreService,
  ) { }


  isLoggedIn(): Boolean {
    if (this.ls.getItem("hashClient") == null) {
      return false;
    } else {
      return true;
    }
  }

  /* Implementación de servicios api nodejs */
  LoginAdmin(body: String) {
    return this.http.post(this.constants.server2 + 'admins/login', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }

  getAllClientes() {
    return this.http.get(this.constants.server2 + 'clientes/findAll', this.constants.getHeaders2()).map(
      (response: Response) => {
        const data = response.json();
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }
  /* Implementación de servicios api nodejs */

  LoginClient(body: String) {
    return this.http.post(this.constants.PATH_API + 'LoginClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }

  SetCarOnWorkshop(body: String) {
    return this.http.post(this.constants.PATH_API + 'SetCarOnWorkshop.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }


  SetDateTentative(body: String) {
    return this.http.post(this.constants.PATH_API + 'SetDateTentative.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }

  CheckTrackingForTentative(body: String) {
    return this.http.post(this.constants.PATH_API + 'CheckTrackingForTentative.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }

  GetListTrackingTentative() {
    var params = {
      idClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetListTrackingTentative.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListCarsOk() {
    var params = {
      idClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetListCarsOk.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetAllCars() {
    var params = {
      idClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'Cars/GetCarsByClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  GetAllCars2() {
    var idClient = this.ls.getItem("idClient")

    return this.http.get(this.constants.PATH_API2 + 'cars/getAllCarsByClient/' + idClient, this.constants.getHeaders2()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListCarsNoResponse() {
    var params = {
      hashClient: this.ls.getItem("hashClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetListCarsNoResponse.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListCarsOkByClient(body) {
    return this.http.post(this.constants.PATH_API + 'Clients/GetListCarsOk.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListToSchedule() {
    var params = {
      idClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetListTrackingToSchedule.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListAllFinesCars() {
    var idClient = this.ls.getItem("idClient")
    console.log(idClient)
    return this.http.get(this.constants.PATH_API2 + 'cars/getAllFinesGeneralByClient/' + idClient, this.constants.getHeaders2()).map(
      (response: Response) => {
        const data = response.json();
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  RequestFinesByClient() {
    var params = {
      hashClient: this.ls.getItem("hashClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'CronGetCarFines.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListTrackingWithSchedule() {
    var params = {
      idClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetListTrackingWithSchedule.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetTrackingByClient(body) {
    return this.http.post(this.constants.PATH_API + 'Trackings/GetHistoryTrackingsByClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetListTrackingOnWorkshop() {
    var params = {
      idClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetListTrackingOnWorkshop.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetWorshopsActive() {
    return this.http.get(this.constants.PATH_API + 'GetListWokshopsActives.php', this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  SetAppointment(body: String) {
    return this.http.post(this.constants.PATH_API + 'SetAppointment.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }

  EditDateAppointment(body: String) {
    return this.http.post(this.constants.PATH_API + 'EditDateAppointment.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }

  SetStepsOnWorkshop(body: String) {
    return this.http.post(this.constants.PATH_API + 'SetStepsOnWorkshop.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }


  SendEmailNotification(body: String) {
    return this.http.post(this.constants.PATH_API + 'SendNotificationToTracking.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }


  SendSMSNotification(body: String) {
    return this.http.post(this.constants.PATH_API + 'SendSMSNotificationToTracking.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    )
      .catch(
        (error: Response) => {
          return Observable.throw('Something went wrong');
        }
      );
  }



  GetGpsCarsByClient(body: String) {
    return this.http.post(this.constants.PATH_API + 'GetGPSCarsByClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetAllClients() {
    return this.http.post(this.constants.PATH_API + 'Clients/GetAllClients.php', this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetClientByGroup(idGroup) {
    return this.http.post(this.constants.PATH_API + 'Clients/GetClientByGroup.php?idGroup=' + idGroup, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  AddNewClient(body) {
    return this.http.post(this.constants.PATH_API + 'Clients/AddClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  UpdateClient(body) {
    return this.http.post(this.constants.PATH_API + 'Clients/UpdateClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  AddCarToTracking(body) {
    return this.http.post(this.constants.PATH_API + 'AddCarToTracking.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }



  //WORKSHOPS


  GetAllWorkshops() {
    return this.http.post(this.constants.PATH_API + 'Workshops/GetAllWorkshops.php', this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  AddNewWorkshop(body) {
    return this.http.post(this.constants.PATH_API + 'Workshops/AddWorkshop.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  UpdateWorkshop(body) {
    return this.http.post(this.constants.PATH_API + 'Workshops/UpdateWorkshop.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }



  //ADMINS


  GetAllAdmins() {
    return this.http.post(this.constants.PATH_API + 'Admins/GetAll.php', this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  AddAdmin(body) {
    return this.http.post(this.constants.PATH_API + 'Admins/AddAdmin.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  UpdateAdmin(body) {
    return this.http.post(this.constants.PATH_API + 'Admins/UpdateAdmin.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  UpdateCarOdometer(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/UpdateCarOdometer.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetDashboardClient(month: String) {
    var params = {
      idClient: this.ls.getItem("idClient"),
      month: month
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GetDashboardClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  GetCarInfoByHash(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/GetCarInfo.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  UpdateCarInfo(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/UpdateCarInfo.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  AddNewCar(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/AddNew.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  AddCallTracking(body) {
    return this.http.post(this.constants.PATH_API + 'Trackings/AddCallTracking.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }



  UpdateClientCar(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/UpdateCarClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  UpdateCarNoResponse(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/UpdateCarNoResponse.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  SetCarToOk(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/SetCarToOk.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  SetTrackingToOk(body) {
    return this.http.post(this.constants.PATH_API + 'Trackings/SetTrackingToOk.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetCarFines(plate) {
    return this.http.get(this.constants.PATH_API2 + 'cars/getFinesByCar/'+plate, this.constants.getHeaders2()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetTrackingsByCar(body) {
    return this.http.post(this.constants.PATH_API + 'Trackings/GetHistoryTrackingsByCar.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetExternalServiceByCar() {
    var params = {
      hashClient: this.ls.getItem("idClient")
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'Cars/GetExternalServiceByCar.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  ChangeServiceCosto(body) {
    return this.http.post(this.constants.PATH_API + 'Trackings/ChangeServiceCost.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  CarNoResponseRelease(body) {
    return this.http.post(this.constants.PATH_API + 'Cars/CarNoResponseRelease.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  GetPhotosByTracking(hashTracking) {
    var params = {
      hashTracking: hashTracking
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'Trackings/GetPhotosByTracking.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  GetConsumesByCentroCosto(centroCosto) {
    var params = {
      centroCosto: centroCosto
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'GasolineConsume/GetConsumesByCentroCosto.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  DeleteTracking(hashTracking, hashCar) {
    var params = {
      hashTracking: hashTracking,
      hashCar: hashCar
    }
    let body = JSON.stringify(params)
    return this.http.post(this.constants.PATH_API + 'Trackings/DeleteTracking.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  PruebaGetXML() {
    return this.http.get(this.constants.PATH_API + 'ImportFacturaGasPrueba.php', this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  GetVerificationsCarByClient(body) {
    return this.http.post(this.constants.PATH_API + 'Verifications/GetVerificationsCarByClient.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

  VerificationChangeStatus(body) {
    return this.http.post(this.constants.PATH_API + 'Verifications/ChangeStatus.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }


  DeleteAllConsumes(body) {
    return this.http.post(this.constants.PATH_API + 'GasolineConsume/DeleteAllConsumes.php', body, this.constants.getHeaders()).map(
      (response: Response) => {
        const data = response.json();
        console.log(data);
        return data;
      }
    ).catch(
      (error: Response) => {
        return Observable.throw('Something went wrong');
      }
    );
  }

}