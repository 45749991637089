import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'admin/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'checking',
    loadChildren: () => import('./views/checking/checking.module').then(m => m.CheckingModule),
    data: { title: '' }
  },
  {
    path: 'schedule',
    loadChildren: () => import('./views/scheduleTentative/scheduleTentative.module').then(m => m.ScheduleTentativeModule),
    data: { title: '' }
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin/dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },
      {
        path: 'dashboard/eficiente',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },
      {
        path: 'dashboard/ubicado',
        loadChildren: () => import('./views/map/map.module').then(m => m.AppMapModule),
        data: { title: 'Ubicado', breadcrumb: 'Ubicado' }
      },
      {
        path: 'dashboard/util',
        loadChildren: () => import('./views/util/util.module').then(m => m.UtilModule),
        data: { title: 'Útil', breadcrumb: 'Útil' }
      },
      {
        path: 'admin/agendarCita',
        loadChildren: () => import('./views/newAppointment/newAppointment.module').then(m => m.newAppointmentModule),
        data: { title: 'Nueva Cita', breadcrumb: 'Nueva Cita' }
      },
      {
        path: 'admin/cambiarEstatus',
        loadChildren: () => import('./views/changeOrderStatus/changeOrderStatus.module').then(m => m.ChangeOrderStatusModule),
        data: { title: 'Nueva Cita', breadcrumb: 'Cambiar Estatus a Orden de Servicio' }
      },
      {
        path: 'admin/listOnNotification',
        loadChildren: () => import('./views/admin/ListOnNotification/listOnNotif.module').then(m => m.ListOnNotifModule),
        data: { title: 'Autos En Notificacion', breadcrumb: 'Autos En Notificacion' }
      },
      {
        path: 'admin/listToSchedule',
        loadChildren: () => import('./views/admin/listToSchedule/listToSchedule.module').then(m => m.ListToScheduleModule),
        data: { title: 'Autos para Agendar', breadcrumb: 'Autos para Agendar' }
      },
      {
        path: 'admin/listOnWorkshop',
        loadChildren: () => import('./views/admin/listOnWorkshop/listOnWorkshop.module').then(m => m.ListOnWorkshopModule),
        data: { title: 'Autos en Taller', breadcrumb: 'Autos en Taller' }
      },
      {
        path: 'admin/listCarOk',
        loadChildren: () => import('./views/admin/listCarOk/listCarOk.module').then(m => m.ListCarOkModule),
        data: { title: 'Autos en Taller', breadcrumb: 'Autos en Estado Óptimo' }
      },
      {
        path: 'admin/listWithSchedule',
        loadChildren: () => import('./views/admin/listWithSchedule/listWithSchedule.module').then(m => m.ListToScheduleModule),
        data: { title: 'Autos en Taller', breadcrumb: 'Autos con cita' }
      },
      {
        path: 'admin/listTrackingsByClient',
        loadChildren: () => import('./views/admin/ListTrackingsByClient/ListTrackingsByClient.module').then(m => m.ListTrackingsByClient),
        data: { title: 'Servicios por cliente', breadcrumb: 'Servicios por cliente' }
      },
      {
        path: 'admin/listExternalServices',
        loadChildren: () => import('./views/admin/Cars/carServicesExternals/carServicesExternals.module').then(m => m.CarExternalServices),
        data: { title: 'Actualización de Odometros', breadcrumb: 'Actualización de Odometros' }
      },

      {
        path: 'admin/listAllFinesCars',
        loadChildren: () => import('./views/admin/ListAllFinesCars/listAllFinesCars.module').then(m => m.ListAllFinesCarsModule),
        data: { title: 'Multas Totales', breadcrumb: 'Multas Totales' }
      },
      {
        path: 'admin/photosByTracking',
        loadChildren: () => import('./views/admin/PhotosByTracking/photosByTracking.module').then(m => m.PhotosByTrackingModule),
        data: { title: 'Fotos de Tracking', breadcrumb: 'Foto de Tracking' }
      },
      {
        path: 'cruds/listClients',
        loadChildren: () => import('./views/cruds/cruds.module').then(m => m.CrudsModule),
        data: { title: 'Autos en Taller', breadcrumb: 'Clientes' }
      },
      {
        path: 'cruds/workshops',
        loadChildren: () => import('./views/admin/Workshops/workshops.module').then(m => m.WorkshopsModule),
        data: { title: 'Talleres', breadcrumb: 'Talleres' }
      },
      {
        path: 'cruds/admins',
        loadChildren: () => import('./views/admin/Admins/admins.module').then(m => m.AdminsModule),
        data: { title: 'Administradores', breadcrumb: 'Administradores' }
      },
      {
        path: 'cruds/carEditOdometer',
        loadChildren: () => import('./views/CarEditOdometer/carEditOdometer.module').then(m => m.CarEditOdometerModule),
        data: { title: 'Actualizar Odometro', breadcrumb: 'Actualizar Odometro' }
      },
      {
        path: 'cruds/editCar',
        loadChildren: () => import('./views/admin/Cars/editCar.module').then(m => m.EditCarModule),
        data: { title: 'Actualizar Auto', breadcrumb: 'Actualizar Auto' }
      },
      {
        path: 'cruds/addCar',
        loadChildren: () => import('./views/admin/Cars/addCar/addCar.module').then(m => m.AddCarModule),
        data: { title: 'Nuevo Auto', breadcrumb: 'Nuevo Auto' }
      },
      {
        path: 'cruds/importCars',
        loadChildren: () => import('./views/admin/Cars/importCars/importCars.module').then(m => m.ImportCarsModule),
        data: { title: 'Importar Autos', breadcrumb: 'Importar Autos' }
      },
      {
        path: 'admin/listAllCars',
        loadChildren: () => import('./views/admin/ListAllCars/ListAllCars.module').then(m => m.ListAllCarsModule),
        data: { title: '' }
      },
      {
        path: 'carFines',
        loadChildren: () => import('./views/admin/Cars/carFines/carFines.module').then(m => m.CarFinesModule),
        data: { title: '' }
      },
      {
        path: 'carTrackings',
        loadChildren: () => import('./views/admin/Cars/carTrackings/carTrackings.module').then(m => m.CarTrackingsModule),
        data: { title: '' }
      },
      {
        path: 'admin/listCarNoResponse',
        loadChildren: () => import('./views/admin/listNoResponse/ListNoResponse.module').then(m => m.ListNoResponseModule),
        data: { title: 'Autos Sin Respuesta', breadcrumb: 'Autos Sin Respuesta' }
      },
      {
        path: 'admin/facturaGas',
        loadChildren: () => import('./views/admin/facturaGas/facturaGas.module').then(m => m.FacturaGasModule),
        data: { title: 'Facturación de Consumos', breadcrumb: 'Facturación de Consumos' }
      },
      {
        path: 'admin/importGasConsumes',
        loadChildren: () => import('./views/admin/importConsumeGas/importConsumeGas.module').then(m => m.ImportConsumeGasModule),
        data: { title: 'Importar Consumes', breadcrumb: 'Importar Consumes' }
      },
      {
        path: 'cruds/importBillDate',
        loadChildren: () => import('./views/importBillDate/importBillDate.module').then(m => m.ImportBillDateModule),
        data: { title: 'Importar Fecha Factura', breadcrumb: 'Importar Fecha Factura' }
      },
      {
        path: 'cruds/importStateLocation',
        loadChildren: () => import('./views/importStateLocation/importStateLocation.module').then(m => m.ImportStateLocationModule),
        data: { title: 'Importar Estados de emplacamiento', breadcrumb: 'Importar Estados de emplacamiento' }
      },
      {
        path: 'admin/listVerifications',
        loadChildren: () => import('./views/admin/ListVerifications/listVerifications.module').then(m => m.ListVerificationsModule),
        data: { title: 'Lista de Autos por Verificar', breadcrumb: 'Lista de Autos por Verificar' }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

