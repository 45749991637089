import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { MantenmeServices } from "../services/ws/MantenmeServices";
import { LocalStoreService } from "../services/local-store.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private mantenmeServices: MantenmeServices) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.mantenmeServices.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(["/sessions/admin"], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
  }
}
