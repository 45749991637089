import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { MantenmeServices } from 'app/shared/services/ws/MantenmeServices';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from 'app/shared/services/local-store.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;


  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }]
  currentLang = this.availableLangs[0];


  public clients:any[] = [];
  currentCllient:string = "Selecciona un cliente"


  public egretThemes;
  public layoutConf:any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private loadService:AppLoaderService,
    public mantenmeServices: MantenmeServices,
    public router:Router,
    private localStorage:LocalStoreService,
    public route:ActivatedRoute,
    private ls:LocalStoreService
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    //this.translate.use(this.currentLang.code);
    this.requestData();
  }

  
  requestData(){
    this.mantenmeServices.getAllClientes().subscribe(
      (response:any) => {
        console.log("RESPUESTA:" + response)
        if(response.code == '0000'){
          this.clients = response.data;
          if(this.ls.getItem("contactName")!=null){
            this.currentCllient =this.ls.getItem("contactName")
          }
        }
      },
      (error) => {
        console.log(error);
    });
  }



  setClient(client){
    this.ls.setItem("idClient", client._id)
    this.ls.setItem("hashClient", client._id)
    this.ls.setItem("contactName", client.nombre)
    this.currentCllient = this.ls.getItem("contactName")
    this.mantenmeServices.subject.next("Eureka");
  }



  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true})
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true})

  }

  onSearch(e) {
    //   console.log(e)
  }





}